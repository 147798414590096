html,
body {
    padding: 0px;
    margin: 0px;
    height: 100%;
}

body {
    font-family: sans-serif;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
