html,
body {
    padding: 0px;
    margin: 0px;
    height: 100%;
}

body {
    font-family: sans-serif;
}

#root {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.prompt {
    border-radius: 4px !important;
}

.pointer-cursor {
    cursor: pointer;
}

.marker-icon {
    color: black;
    text-shadow: 2px 2px 1px white, 2px -2px 1px white, -2px 2px 1px white, -2px -2px 1px white;
    outline: none;
}

.marker-icon-selected {
    color: #ffba00;
}

.marker-icon img {
    width: 26px;
    height: 26px;
}

.marker-icon div {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
}

.layer-selection-control {
    width: 34px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.layer-selector {
    width: 30px;
    height: 31px;
    cursor: pointer;
    background-size: contain;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    transition: all 0.1s;
}

.layer-selector:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom: 1px solid #bbbbbb;
}

.layer-selector:last-child {
    height: 30px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom: none;
}

.layer-selector:hover {
    filter: brightness(95%);
    -webkit-filter: brightness(95%);
    -moz-filter: brightness(95%);
    -o-filter: brightness(95%);
    -ms-filter: brightness(95%);
    transition: all 0.1s;
}

.castle-detail-view {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #f5f6fa;
}

.castle-detail-view a {
    color: #0097e6;
    text-decoration: none;
}

.castle-detail-view a:hover {
    color: #00a8ff;
    text-decoration: underline;
}

.castle-detail-view-content {
    padding: 20px;
    color: #353b48;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-flex: 1 1;
            flex: 1 1;
}

@media screen and (max-width: 767px) {
    .castle-detail-view-content {
        background-color: #ffffff;
        padding: 0px 0px 20px 0px;
    }
}

.castle-detail-view-content .summary {
    line-height: 1.5em;
}

.castle-detail-view-bottom-link-row {
    -webkit-flex: 0 0 50px;
            flex: 0 0 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    background-color: #dcdde1;
}

.optional-image {
    background-color: #2f3640;
    height: 300px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.optional-image-img {
    max-height: 300px;
    max-width: 100%;
}

.share-popup-trigger {
    cursor: pointer;
    color: #0097e6;
}

.share-popup-trigger:hover {
    color: #00a8ff;
}

