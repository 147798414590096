.castle-detail-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f5f6fa;
}

.castle-detail-view a {
    color: #0097e6;
    text-decoration: none;
}

.castle-detail-view a:hover {
    color: #00a8ff;
    text-decoration: underline;
}

.castle-detail-view-content {
    padding: 20px;
    color: #353b48;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
}

@media screen and (max-width: 767px) {
    .castle-detail-view-content {
        background-color: #ffffff;
        padding: 0px 0px 20px 0px;
    }
}

.castle-detail-view-content .summary {
    line-height: 1.5em;
}

.castle-detail-view-bottom-link-row {
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #dcdde1;
}
