.optional-image {
    background-color: #2f3640;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.optional-image-img {
    max-height: 300px;
    max-width: 100%;
}
