.marker-icon {
    color: black;
    text-shadow: 2px 2px 1px white, 2px -2px 1px white, -2px 2px 1px white, -2px -2px 1px white;
    outline: none;
}

.marker-icon-selected {
    color: #ffba00;
}

.marker-icon img {
    width: 26px;
    height: 26px;
}

.marker-icon div {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
}
