.layer-selection-control {
    width: 34px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.layer-selector {
    width: 30px;
    height: 31px;
    cursor: pointer;
    background-size: contain;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.layer-selector:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom: 1px solid #bbbbbb;
}

.layer-selector:last-child {
    height: 30px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom: none;
}

.layer-selector:hover {
    filter: brightness(95%);
    -webkit-filter: brightness(95%);
    -moz-filter: brightness(95%);
    -o-filter: brightness(95%);
    -ms-filter: brightness(95%);
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}
